<template>
  <div v-if="dataObject">
    <q-menu v-if="dataObject.Children.length > 0" fit>
      <q-list style="min-width: 100px">
        <q-item
          clickable
          v-close-popup
          v-for="d in dataObject.Children"
          :dataObject="d"
          :key="d.ModuleID"
        >
          <q-item-section
            ><span><q-icon :name="d.RouteIcon" /> {{ d.ModelName }}</span>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
    <q-item
      v-else
      clickable
      v-close-popup
      :dataObject="dataObject"
      :key="dataObject.ModuleID"
      @click="onClickMenu(dataObject)"
      :to="dataObject.RoutePath"
    >
      <q-item-section>
        <span
          ><q-icon :name="dataObject.RouteIcon" />
          {{ dataObject.ModelName }}</span
        ></q-item-section
      >
    </q-item>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SlideMenuItem",
  props: {
    dataObject: {},
  },
  computed: {
    ...mapGetters("appUser", ["currentModuleID"]),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("appUser", ["actSetCurrentModuleID"]),
    onClickMenu(e) {
      this.actSetCurrentModuleID(e.ModuleID);
    },
  },
};
</script>
